import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const Home = React.lazy(() => import('../../pages/Home'));
const OpenAccount = React.lazy(() => import('../../pages/OpenAccount'));
const VerifyEmail = React.lazy(() => import('../../pages/VerifyEmail'));
const ContactUs = React.lazy(() => import('../../pages/ContactUs'));
const PrivacyPolicy = React.lazy(() => import('../../pages/PrivacyPolicy'));
const Disclaimers = React.lazy(() => import('../../pages/Disclaimers'));
const Account = React.lazy(() => import('../../pages/Account'));
const Finalise = React.lazy(() => import('../../pages/Finalise'));
const Sso = React.lazy(() => import('../../pages/Sso'));
const IBLogin = React.lazy(() => import('../../pages/IBLogin'));

const App = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path={ROUTES.LOGIN} element={<Home isLoginOpen />} />
          <Route path={ROUTES.OPEN_ACCOUNT + '/:step?'} element={<OpenAccount />} />
          <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.DISCLAIMERS} element={<Disclaimers />} />
          <Route path={ROUTES.USER_ACCOUNT} element={<Account />} />
          <Route path={ROUTES.FINALISE} element={<Finalise />} />
          <Route path={ROUTES.SSO} element={<Sso />} />
          <Route path={ROUTES.IB_LOGIN} element={<IBLogin />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
